import React from "react"

import TestimonialPage from "../components/testimonialPage"
import Layout from "../components/layout"
import SEO from "../components/seo"

function ContactPage() {
  return (
    <Layout>
      <SEO
        keywords={[
          `testimonials`,
          `sincerely eden`,
        ]}
        title="Sincerely Eden | What Women Are Saying."
        description="Read how we've helped women regain their power."
      />
      <TestimonialPage />
    </Layout>
  )
}

export default ContactPage

import React from 'react';

const testimonials = [
  {
    quote: (
      <>
        &quot;I recently worked with Eden in regards to learning about how my cycle and hormones can be used as a form of contraception (and for others, conception). I&apos;ve become increasingly aware over the last several months about how hormones affect our bodies in more ways than I could have imagined. When the opportunity arose to work with Eden, I very hopefully applied, and was chosen to work together via Zoom as I am in Canada and she is in Australia. Prior to our first meeting, I was given a very detailed summary of what to expect during our first session, as well as very detailed resources to print off, and charts to familiarize myself with. Everything was explained in great detail on our zoom session. Eden was incredibly professional. Despite knowing her in childhood, she remained friendly but focused on the task at hand, which I was impressed with. What some may consider awkward issues and terms were discussed, and there was never any judgement from Eden. I continue to chart and graph my cycle as I&apos;m excited to implement my knowledge into my future. I will continue to learn about this topic, as I believe it to be very important. I love the app (Femm) that Eden recommended and will continue to use this daily.
        <br />
        <br />
        <b>Thank you so much, Eden, for your patience and dedication to working with me. It was an amazing experience that I feel every woman can benefit from.</b>&quot;
      </>
    ),
    author: "LG",
    location: "Canada",
    program: "Flow Freedom Basics"
  },
  {
    quote: <>
      <div>&quot;Working with Eden the past several months has been such a wonderful experience! Not only is she incredibly knowledgeable but you can tell that she is genuinely invested and passionate in helping and empowering women in their bodies. Working with Eden has not only allowed me to learn more about my body and my hormones, but it has also made me excited to learn about myself in a whole new way. Eden offers very digestible information in an interactive and engaging manner! Eden’s thoughtful approach, warm personality, and sincere desire to help, created a safe space where I felt comfortable sharing and expressing personal experiences and concerns. Eden made sure that I left every session feeling comfortable and confident in the material we covered and was always happy to help answer questions in-between our sessions.</div>
      <br/>
      <b>Through working with Eden, I have a whole new appreciation for my body, my cycle, and my overall health and wellbeing. I can&apos;t say enough good things about working with Eden and the invaluable knowledge and guidance she has been able to provide.</b>
      <br/><br/>
      <div>I would absolutely recommend working with Eden, it was an absolute pleasure!&quot;</div>
    </>,
    author: "RB",
    location: "Canada",
    program: "Flow Freedom Advanced"
  },
  {
    quote: <>
      <div>&quot;When I first started working with Eden I thought I had a pretty good handle on my cycle and what was happening internally. As we progressed throughout this process I was blown away by the depth of charting with FEMM. Eden was an excellent support and broke down the process in an accessible and easy to grasp way. Not only did she explain things with ease but was able to break down the &apos;why&apos; in the charting process that allowed me to feel more connected to the process. </div>
      <br/>
      <b>After finishing this process I now feel like I have a totally new view on my cycle and body. Eden made space for this to be such an empowering experience as she shared tools and missing links in my own Cycle Charting journey.</b>
      &quot;
    </>,
    author: "KB",
    location: "Canada",
    program: "Flow Freedom Advanced"
  },
  {
    quote: <>
      <div>&quot;After engaging in a workshop that Eden hosted, I came away with great knowledge and more understanding of my body. When the opportunity came to work with Eden to continue this learning and better understand my cycle and body, I jumped at the chance. I knew how much I gained from the short time with her previously, and really loved her calm inviting nature. During our sessions, Eden was so patient and answered all my questions along the way and was extremely knowledgeable in all the content as well as a lot of other information due to her background. Eden is flexible and understanding of change and very open minded and non judgmental. I have learnt so much over the 3 months of working with Eden and feel I now have the knowledge to understand my body and cycle.</div>
      <br/>
      <b>This has been an incredibly helpful and eye opening experience and Eden has been an amazing teacher and guide. I have regular cycles and thought I had a pretty good understanding of things but I have learnt so much and am so much more in tune with my body and what is going on.</b>&quot;
    </>,
    author: "KB",
    location: "Canada",
    program: "Flow Freedom Advanced"
  },
  {
    quote: <div>&quot;Eden has such a magical aura of softness and femininity that instantly puts you at ease. Thank you for holding such a sacred space.&quot;</div>,
    author: "JM",
    location: "Australia",
  },
  {
    quote: <div>&quot;I never realized how in tune I actually am with my body. Eden helped remind me of this, and I am so grateful she did.&quot;</div>,
    author: "SR",
    location: "Canada",
  },
  {
    quote: <div>&quot;Instead of calling it &apos;shark week&apos; I now call it &apos;honour my body&apos; week and put a lot less pressure on myself than in the past. Eden taught me how to change the language and embrace my womanhood and it&apos;s liberating.&quot;</div>,
    author: "CL",
    location: "Canada",
  },
  {
    quote: <div>&quot;Body awareness is everything. I never realized how much alcohol affected me and my hormones until Eden helped me tune in.&quot;</div>,
    author: "AJ",
    location: "Canada",
  },
  {
    quote: <div>&quot;For anyone looking to make healthy changes, improve their hormonal health and find balance, Eden is your girl. There&apos;s so much information out there and it was so helpful to have someone so knowledgeable to discuss my goals.&quot;</div>,
    author: "DO",
    location: "Canada",
  }
];

const LargeTestimonialCard = ({ quote, author, location, program }) => {
  return (
    <div className="mb-8 page-break-inside-avoid">
      <div className="bg-white rounded-xl shadow-lg p-8 md:p-10 h-full">
        <blockquote>
          <div className="relative">
            <p className="font-roboto italic text-gray-600 text-lg leading-relaxed">
              {quote}
            </p>
          </div>
          
          <footer className="mt-6">
            <div>
              <p className="text-pink-600 font-roboto font-medium">
                {author}, {location}
              </p>
              <p className="text-red-600 font-roboto">
                {program}
              </p>
            </div>
          </footer>
        </blockquote>
      </div>
    </div>
  );
};

const LargeTestimonials = () => {
  return (
    <div className="bg-blob-scatter-small bg-auto bg-contain bg-no-repeat bg-center py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-4xl md:text-5xl font-ultra text-center text-gray-900 mb-16">
          What Women Are Saying
        </h2>
        
        {/* Desktop layout with CSS Grid instead of columns */}
        <div className="hidden lg:grid lg:grid-cols-2 lg:gap-8">
          {testimonials.map((testimonial, index) => (
            <LargeTestimonialCard
              key={index}
              quote={testimonial.quote}
              author={testimonial.author}
              location={testimonial.location}
              program={testimonial.program}
            />
          ))}
        </div>

        {/* Mobile layout */}
        <div className="lg:hidden space-y-8">
          {testimonials.map((testimonial, index) => (
            <LargeTestimonialCard
              key={index}
              quote={testimonial.quote}
              author={testimonial.author}
              location={testimonial.location}
              program={testimonial.program}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default LargeTestimonials;
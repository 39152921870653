import FeatureWithImage from "../components/featureWithImage"
import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

function ContactPage() {
  return (
    <Layout>
      <SEO
        keywords={[
          `about page`,
          `sincerely eden`,
          `challenge`,
          `about sincerely eden`
        ]}
        title="Sincerely Eden | Get to know me"
        description="I am a Canadian born, Vancouver Island girly, currently residing in Australia with my amazing little family. I am an incredibly passionate women’s health and body literacy advocate, and I absolutely LOVE my morning coffee during the sunrise. Simple, slow living is my current focus, and I feel very at peace with this change in pace for me."
      />
      <FeatureWithImage />
    </Layout>
  )
}

export default ContactPage

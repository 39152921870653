import React from "react"
import { CameraIcon } from "@heroicons/react/solid"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export const pageQuery = graphql`
  query FeatureQuery {
    chairPants: file(relativePath: { eq: "white-pants-chair.jpeg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 700
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    teacup: file(relativePath: { eq: "teacup.jpeg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 700
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

const thingsIAdore = [
  <>chocolate - despite hating it as a child</>,
  <>the love + lessons of motherhood</>,
  <>the power of breath work - I am also a certified yoga teacher</>,
  <>intuitive movement - working with my natural energy shifts</>,
  <>my morning coffee - if not obvious already ;)</>,
  <>nature walks + a good podcast</>,
  <>farmers markets + nutritious, whole foods</>,
  <>all things travel - by road, sea, or plane</>,
  <>my sister friends + family near and far</>,
  <>a good read - whether for education or pleasure</>
]

const photographer = ""

export default function Example() {
  const data = useStaticQuery(pageQuery)
  const image = getImage(data?.chairPants)
  const image2 = getImage(data?.teacup)

  return (
    <div className="bg-white overflow-hidden bg-blob-scatter-small bg-cover bg-no-repeat bg-center">
      <div className="relative max-w-7xl mx-auto py-10 px-4 sm:px-6 lg:px-8">
        <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="relative lg:row-start-1 lg:col-start-1">
            <div className="relative text-base rounded-lg overflow-hidden mx-auto max-w-prose lg:max-w-none">
              <figure>
                <div>
                  <GatsbyImage
                    className="rounded-lg shadow-lg object-cover object-center "
                    image={image}
                    alt="Eden standing in front of be kind artwork"
                    width={1184}
                    height={1376}
                  />
                </div>
                {photographer && (
                  <figcaption className="mt-3 flex text-sm text-jet">
                    <CameraIcon
                      className="flex-none w-5 h-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-2">Photograph by Jon Doe</span>
                  </figcaption>
                )}
              </figure>
            </div>
          </div>
          <div className="mt-8 lg:mt-0">
          <div>
            <h2 className="mt-2 mb-6 text-4xl leading-8 font-extrabold  text-gray-900 sm:text-5xl">
              Hey Lovely, <span className="text-sea-pink-700">it&apos;s so nice to meet you!</span>
            </h2>
          </div>
            <div className="text-base max-w-prose mx-auto lg:max-w-none">
              <p className="text-lg text-jet">
                I am a Canadian born, Vancouver Island girly, currently residing in Australia with my amazing little family. I am an incredibly passionate women’s health and body literacy advocate, and I absolutely LOVE my morning coffee during the sunrise. Simple, slow living is my current focus, and I feel very at peace with this change in pace for me.
              </p>
            </div>
            <div className="mt-5 prose prose-sea-pink text-jet mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
              <p>
               <b>So, what lead me to this work?</b>
              </p>
              <p>
              I personally experienced a myriad of symptoms due to hormonal imbalances after using The Pill in my teens/early 20’s. Acne, chronic fatigue, anxiety, weight retention, insomnia, amenorrhea (aka lack of period) for 8+ years, you name it. After trying all the short-term fixes I was recommended without success, I was at a loss for options. This nudged me to take it upon myself to learn more about my cycle, find the root causes of my struggles, and implement natural practices to bring my body back into balance. It wasn’t a quick fix, but a lasting one. I have now been off hormonal contraception for 15+ years, preventing pregnancy naturally for most of that time, while recently conceiving intentionally with great ease.  All while continuing to feel harmonious in my body. My experience, combined with a Bachelor of Science in Nursing, and Certifications in Integrative Nutrition and Fertility Awareness Education, has lead me to this mission. A mission to use my diverse knowledge to encourage women to harness their ability to live an informed, confident, and comfortable life. I am here to help you understand exactly what’s going on each and every day of your cycle, offer you natural but effective alternatives to contraception if you so desire, and provide maintainable lifestyle tips to meet your health and fertility goals now and into the future.
              </p>
            </div>
          </div>
        </div>
      </div>


      <div className="relative max-w-7xl mx-auto py-10 px-4 sm:px-6 lg:px-8">
        <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="relative lg:row-start-1 lg:col-start-2">
            <div className="relative text-base rounded-lg overflow-hidden mx-auto max-w-prose lg:max-w-none">
              <figure>
                <div>
                  <GatsbyImage
                    className="rounded-lg shadow-lg object-cover object-center "
                    image={image2}
                    alt="Eden standing in front of be kind artwork"
                    width={1184}
                    height={1376}
                  />
                </div>
                {photographer && (
                  <figcaption className="mt-3 flex text-sm text-jet">
                    <CameraIcon
                      className="flex-none w-5 h-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-2">Photograph by Jon Doe</span>
                  </figcaption>
                )}
              </figure>
            </div>
          </div>
          <div className="mt-8 lg:mt-0">
          <div>
            <h2 className="mt-2 text-3xl leading-8 font-extrabold  text-gray-900 sm:text-5xl">
              More Things I <span className="text-sea-pink-700">Unapologetically Adore</span>
            </h2>
          </div>
         <br />
          {thingsIAdore.map(item => (
                <li className="mt-2" key={item}>
                  {item}
                </li>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

import React, { useState, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import useMailchimp from "../hooks/useMailchimp"
import Notification from "../components/notification"

export const pageQuery = graphql`
  query LetsChat {
    image1: file(relativePath: { eq: "phone.jpeg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 490
          height: 635
          transformOptions: { cropFocus: SOUTH }
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

export default function Example() {
  const data = useStaticQuery(pageQuery)
  const letsChatImg = getImage(data?.image1)
  const [form, setForm] = useState({
    name: "",
    phone: "",
    message: "",
    email: ""
  })
  const { send } = useMailchimp()

  const [show, setShow] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const hidden = useRef(false)

  if (isSuccess && hidden.current === false) {
    setShow(true)
    hidden.current = true

    setTimeout(() => setShow(false), 10000)
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setIsLoading(true)
    try {
      await fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "contact", ...form })
      })
      await send({
        email: form.email,
        name: form.name,
        phone: form.phone,
        message: form.message
      })
      setIsSuccess(true)
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false)
    }
  }

  const handleChange = e => {
    setForm(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const disableForm = isLoading || isSuccess
  return (
    <div className="bg-white bg-stacked-waves bg-cover bg-no-repeat bg-center overflow-hidden">
      <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
          <div>
            <div>
              <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl">
                Let’s <span className="text-sea-pink-700">Chat!</span>
              </h1>
              <p className="mt-4 text-lg leading-6 text-gray-500">
              Unsure what Flow Freedom option is right for you, or want to know more? I’d love to hear from you! Just fill out the form below, and I’ll reply as soon as I can. 
              </p>
            </div>
            <div className="mt-12">
              <form
                name="contact"
                method="POST"
                onSubmit={handleSubmit}
                data-netlify="true"
                netlify-honeypot="bot-field"
              >
                <input type="hidden" name="bot-field" />
                <div>
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Name
                  </label>
                  <div className="mt-1  mb-2">
                    <input
                      disabled={disableForm}
                      onChange={handleChange}
                      type="text"
                      name="name"
                      id="name"
                      autoComplete="given-name"
                      className="py-3 px-4 block w-full shadow-sm focus:ring-porsche-500 focus:border-porsche-500 border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email
                  </label>
                  <div className="mt-1  mb-2">
                    <input
                      disabled={disableForm}
                      onChange={handleChange}
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      className="py-3 px-4 block w-full shadow-sm focus:ring-porsche-500 focus:border-porsche-500 border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Phone Number
                  </label>
                  <div className="mt-1  mb-2 relative rounded-md shadow-sm">
                    <input
                      disabled={disableForm}
                      onChange={handleChange}
                      type="text"
                      name="phone"
                      id="phone"
                      autoComplete="tel"
                      className="py-3 px-4 block w-full focus:ring-porsche-500 focus:border-porsche-500 border-gray-300 rounded-md"
                      placeholder="0455 555 555"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="message"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Message
                  </label>
                  <div className="mt-1 mb-2">
                    <textarea
                      disabled={disableForm}
                      onChange={handleChange}
                      id="message"
                      name="message"
                      rows={4}
                      className="py-3 px-4 block w-full shadow-sm focus:ring-porsche-500 focus:border-porsche-500 border border-gray-300 rounded-md"
                      defaultValue={""}
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <button
                    disabled={disableForm}
                    type="submit"
                    className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-porsche-700 hover:bg-porsche-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-porsche-500"
                  >
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div>
            <GatsbyImage
              className="rounded-lg shadow-lg mt-16 w-full"
              image={letsChatImg}
            />
          </div>
        </div>
      </div>
      {isSuccess && (
        <Notification
          show={show}
          title={`Thanks for the message ${form.name}!`}
          description="We will be in touch as soon as possible."
        />
      )}
    </div>
  )
}

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

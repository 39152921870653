import React from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"

import SingleQuote from "../components/singleQuote"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Instagram from "../components/instagram2"
import Divider from "../components/divider"
import Heading from "../components/heading"
import FourImageSection from "../components/fourImageSection"
import Hero from "../components/hero"
import CTAbooking from "../components/CTAbooking"

function IndexPage({ data }) {
  const image = getImage(data?.heroImg)
  const quoteImg = getImage(data?.quoteImg)
  const programCTA = getImage(data?.programCTA)
  const insta1 = getImage(data?.insta1)
  const insta2 = getImage(data?.insta2)
  const insta3 = getImage(data?.insta3)
  const insta4 = getImage(data?.insta4)
  const insta5 = getImage(data?.insta5)
  const insta6 = getImage(data?.insta6)
  const insta7 = getImage(data?.insta7)
  const insta8 = getImage(data?.insta8)

  const instaImages = [insta1, insta2, insta3, insta4, insta5, insta6, insta7, insta8]
  return (
    <Layout>
      <SEO
        keywords={[`sincerely eden`, `hormone issues`, `female health couch`]}
        title="Sincerely Eden | Your hormone health go-to girl."
        description="I am here to help you find balance, comfort and liberation as a cyclical woman. By using an evidence-based approach, I make it my mission to fill the gaps in your cycle knowledge, while drawing focus to what’s going on in your unique body."
      />
      <Hero
        cta={false}
        image={image}
        subtitle={
          <>
            <div className=" tracking-widest font-extralight">
            I am here to help you find balance, comfort and liberation as a cyclical woman. By using an evidence-based approach, I make it my mission to fill the gaps in your cycle knowledge, while drawing focus to what’s going on in your unique body. Whether you’re struggling with hormonal imbalances, wanting a natural method to replace your current birth control, or planning for pregnancy, I got you covered!
            </div>
          </>
        }
      />
      <Divider />
      <Heading
        title="my why"
        subtitle="I get you, because I have been you."
        description=""
      />
      <SingleQuote
        img={quoteImg}
        quote="From years of cystic acne, fatigue, anxiety, cravings, and a lack of period for most of my twenties, to an easy pregnancy in my thirties, I know that body literacy and lifestyle choices play a huge role in our health and fertility. It takes knowing exactly what’s going on in your cycle to be able to advocate for yourself, figure out where imbalances stem from, and safely and successfully prevent and/or plan for pregnancy naturally. It is now my passion to offer you the body literacy course that you never had but should’ve had as a young girl. I understand the feelings of defeat, the lack of confidence, the distrust in your body and your frustrations with conventional options, because I have been there. I have also seen many women turn to prescriptions, surgeries, and restrictive modalities well before it was necessary, simply because they never knew there were other ways. I want to pull you out of your state of hopelessness, and provide you with the tools you need to be as informed as possible, so you can achieve your health and fertility goals for life. Because you so deserve it, Darling."
      />
      <Divider />
      <FourImageSection
        title="My difference"
        subtitle="I’m with you, every step of the way"
        description="Every woman has a unique cycle with unique health and family planning goals, so my approach isn’t based on an algorithm, a one-size fits all method, or a ‘guesstimate’. My offerings stem from the information that your biomarkers are communicating to you, so we can get an accurate understanding of what’s going on in your body in each phase of your cycle."
        features={[
          {
            name: "HOLISTIC APPROACH",
            description: "I provide tailored nourishment options and lifestyle tips that promote balance & encourage hormone optimization.",
            imageAlt:
              "Stack of three green cardstock boxes with 3 hole cutouts showing cards inside."
          },
          {
            name: "LIFE-LONG TOOLS ",
            description:
              "The knowledge you gain from this program will take you through each hormonal stage for the rest of your life, so you can embrace any shifts with understanding.",
            imageAlt:
              "Stack of three green cardstock boxes with 3 hole cutouts showing cards inside."
          },
          {
            name: "ONGOING SUPPORT",
            description:
              "To ensure you have the most success & confidence, I am consistently available to you throughout this program.",
            imageAlt:
              "Stack of three green cardstock boxes with 3 hole cutouts showing cards inside."
          },
          {
            name: "EVIDENCE-BASED",
            description:
              "My knowledge is always current and well-studied with certifications renewed annually, bringing you the best information on hand.",
            imageAlt:
              "Stack of three green cardstock boxes with 3 hole cutouts showing cards inside."
          }
        ]}
      />
      <Divider />
      <CTAbooking programCTA={programCTA} />
      {/* <Newsletter /> */}
      <Divider />
      {/* <Features /> */}
      {/* <Divider /> */}
      <Heading
        title=""
        subtitle={
          <>
            Follow along on instagram |{" "}
            <a
              className="text-sea-pink-700"
              href="https://www.instagram.com/sincerelyeden"
              target="__blank"
              rel="noreferrer"
            >
              @sincerelyeden
            </a>
          </>
        }
        description=""
      />
      <Instagram images={instaImages} />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query HeroImageQuery {
    programCTA: file(relativePath: { eq: "sitting-white.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          height: 300
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    heroImg: file(relativePath: { eq: "bathtub.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 600
          height: 850
          quality: 100
          transformOptions: { cropFocus: NORTHWEST }
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    quoteImg: file(relativePath: { eq: "floral-dress.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 600
          height: 650
          quality: 100
          transformOptions: { cropFocus: NORTHWEST }
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    insta1: file(relativePath: { eq: "insta-1.jpeg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          height: 300
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    insta2: file(relativePath: { eq: "insta-2.jpeg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          height: 300
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    insta3: file(relativePath: { eq: "insta-3.jpeg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          height: 300
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    insta4: file(relativePath: { eq: "insta-4.jpeg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          height: 300
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    insta5: file(relativePath: { eq: "insta-5.jpeg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          height: 300
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    insta6: file(relativePath: { eq: "insta-6.jpeg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          height: 300
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    insta7: file(relativePath: { eq: "insta-7.jpeg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          height: 300
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    insta8: file(relativePath: { eq: "insta-8.jpeg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          height: 300
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

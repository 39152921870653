import React from 'react'
import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'

export default function ProductDialog({
  name,
  description,
  image,
  open,
  onClose
}) {
  return (
    <Dialog open={open} onClose={onClose} className="relative z-50">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-black bg-opacity-50 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-[100] w-screen overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-0 md:p-4">
          <DialogPanel
            transition
            className="flex w-full transform text-left text-base transition md:max-w-7xl data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in data-[closed]:md:translate-y-0 data-[closed]:md:scale-95"
          >
            <div className="relative flex h-screen w-full flex-col bg-white pb-5 md:h-auto md:rounded-xl md:p-6 lg:p-8">
              <button
                type="button"
                onClick={onClose}
                className="absolute right-4 top-4 z-[101] text-gray-400 hover:text-gray-500 md:right-6 md:top-6"
              >
                <span className="sr-only">Close</span>
                <XIcon className="h-6 w-6" />
              </button>

              <div className="h-full overflow-y-auto px-4 pb-8 pt-14 md:px-0 md:pb-0 md:pt-0">
                <div className="grid h-full w-full grid-cols-1 items-start gap-x-6 gap-y-8 sm:grid-cols-12 lg:gap-x-8">
                  <div className="col-span-12 md:col-span-4 lg:col-span-5">
                    {image}
                  </div>
                  <div className="col-span-12 md:col-span-8 lg:col-span-7">
                    <h2 className="text-2xl font-bold text-gray-900 sm:pr-12">{name}</h2>

                    <section aria-labelledby="information-heading" className="mt-3">
                      <h3 id="information-heading" className="sr-only">
                        Product information
                      </h3>

                      <div className="mt-6">
                        <h4 className="sr-only">Description</h4>
                        {description}
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  )
}
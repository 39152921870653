/* This example requires Tailwind CSS v2.0+ */
import React from "react"
// import { CheckIcon } from "@heroicons/react/outline"

const defaultFeatures = [
  {
    name: "Invite team members",
    description:
      "You can manage phone, email and chat conversations all from a single mailbox."
  },
  {
    name: "List view",
    description:
      "You can manage phone, email and chat conversations all from a single mailbox."
  },
  {
    name: "Keyboard shortcuts",
    description:
      "You can manage phone, email and chat conversations all from a single mailbox."
  },
  {
    name: "Calendars",
    description:
      "You can manage phone, email and chat conversations all from a single mailbox."
  },
  {
    name: "Notifications",
    description:
      "Find what you need with advanced filters, bulk actions, and quick views."
  },
  {
    name: "Boards",
    description:
      "Find what you need with advanced filters, bulk actions, and quick views."
  },
  {
    name: "Reporting",
    description:
      "Find what you need with advanced filters, bulk actions, and quick views."
  },
  {
    name: "Mobile app",
    description:
      "Find what you need with advanced filters, bulk actions, and quick views."
  }
]

export default function Example({
  title = "title 1",
  subtitle = "subtitle 1",
  subtitle2 = "subtitle 2",
  features = defaultFeatures
}) {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 text-center">
        <div>
          <p className="text-base font-semibold text-center text-sea-pink-700 tracking-wide uppercase">
            {title}
          </p>
          <h3 className="mt-2 text-4xl font-extrabold text-gray-900 text-center">
            {subtitle}
          </h3>
          <p className="mt-4 text-lg text-gray-500">{subtitle2}</p>
        </div>
        <div className="mt-12">
          <dl className="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-1 sm:grid-rows-4 sm:gap-x-6 sm:gap-y-10">
            {features.map((feature, index) => (
              <div key={feature.name} className="relative">
                <dt>
             
                  <p className="text-lg leading-6 font-medium text-gray-900">
                    {feature.name}
                  </p>
                </dt>
                <dd className={`mt-2 ${index === features.length - 1 ? 'text-xl' : 'text-lg'} text-gray-500`}>
                  {index === features.length - 1 ? feature.description : `» ${feature.description}`}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}

import React from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"

import FeaturesList from "../components/featuresList"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Divider from "../components/divider"
import SingleQuote from "../components/singleQuote"
import { openPopupWidget } from "react-calendly"
import { Link } from "gatsby"

import Testimonials from "../components/testimonial"

import ProductList from "../components/productList"
import CTABooking from "../components/CTAbooking"



const onClick = (e) => {
  e.preventDefault()
  openPopupWidget({
    url: "https://calendly.com/sincerelyeden/consultation"
  })
}

const products = [
  {
  id: 1,
  name: (<div>Flow Freedom Basics: <span className="text-sea-pink-700">Health Check</span></div>),
  href: '#',
  description: (
    <div className="font-roboto">
      
      <button
        onClick={onClick}
        type="button"
        className="mb-8 flex w-full items-center justify-center rounded-md border border-transparent bg-sea-pink-700 px-8 py-3 text-base font-medium text-white hover:bg-sea-pink-800 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
      >
        Book free consultation
      </button>
      
      <h2 className="font-roboto text-xl font-bold mb-8">WITHIN THIS PROGRAM, YOU WILL RECEIVE —</h2>
      
      <div className="space-y-8">
        <div>
          <h3 className="text-lg font-bold mb-2">1 live coaching session</h3>
          <p className="text-base">
          Connect with Eden virtually for 1, powerful, 90min education session. During this session, you will be guided through various concepts. From the most basic anatomy refresher, to the intricacies of an optimal cycle, and how your overall health is impacted by your hormones. All while exploring what’s happening in YOUR cycle. This is a private session, allowing you the space and safety to ask any questions, solidify knowledge at your own pace, and gain a basic understanding of your cycle and overall health moving forward.           </p>
        </div>
   
        <div>
          <h3 className="text-lg font-bold mb-2">Ongoing support for 1 full cycle*</h3>
          <p className="text-base italic mb-2">*or up to 2 months if irregular/missing period</p>
          <p className="text-base">
          Consistent, open communication between you and myself is key to building new skills with assurance between calls throughout the program. That being said, you are welcome to contact me anytime throughout your enrolment in Flow Freedom, and I will respond as soon as possible.          </p>
        </div>
   
        <div>
          <h3 className="text-lg font-bold mb-2">Evidence-based modules & worksheets</h3>
          <p className="text-base">
            Everything you are learning comes from years of research and tried and tested methods through FEMM health and the Institute of Integrative Nutrition. I am required to keep my knowledge current to practice successfully and credibly in these fields.
          </p>
        </div>
   
        <div>
          <h3 className="text-lg font-bold mb-2">1 chart follow up
          </h3>
          <p className="text-base">
          To ensure you have gained a basic level of understanding, this edition includes 1, concise, 15min follow up call to go over 1 full cycle chart via app/paper charting, answer any questions you may have, and help explain what may be going on for you hormonally during your cycle.           </p>
        </div>
   
        <div>
          <h3 className="text-lg font-bold mb-2">Lifestyle recommendations</h3>
          <p className="text-base">
          As required, I will offer you lifestyle modifications - inclusive of nutrition upgrades, stress management practices, sleep hygiene techniques, schedule overhauls, and the like - to help support your health and cycle goals.</p>
        </div>
      </div>
    </div>
   )
,
  imageSrc: 'https://tailwindui.com/plus/img/ecommerce-images/product-page-03-product-04.jpg',
  imageAlt: 'Back angled view with bag open and handles to the side.',
},
  {
  id: 2,
  name: <div>Flow Freedom Upleveled: <span className="text-sea-pink-700">Biomarker Expert</span></div>,
  description: (
    <div className="font-roboto">      
           <button
        onClick={onClick}
        type="button"
        className="mb-8 flex w-full items-center justify-center rounded-md border border-transparent bg-sea-pink-700 px-8 py-3 text-base font-medium text-white hover:bg-sea-pink-800 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
      >
        Book free consultation
      </button>
      
      <h2 className="font-roboto text-xl font-bold mb-8">
        WITHIN THIS PROGRAM, YOU WILL RECEIVE EVERYTHING IN FLOW FREEDOM BASICS PLUS —
      </h2>
      
      <div className="space-y-8">
        <div>
          <h3 className="text-lg font-bold mb-2">An extra live coaching session, for a total of 2</h3>
          <p className="text-base">
          Connect with Eden virtually for a total of 2, powerful, 90min education sessions throughout the program. During these potent sessions, you will be guided through various concepts. From the most basic anatomy refresher, to the intricacies of an optimal cycle, and biomarker observations to allow full understanding of your ovulation window. All while exploring what’s happening in YOUR cycle. These are private sessions, allowing you the space and safety to ask any questions, solidify knowledge at your own pace, gain a deep understanding of your cycle health and overall health, become inspired by your practices, share your experiences and successes, and work through activities to ensure you’re feeling confident.           </p>
        </div>
  
        <div>
          <h3 className="text-lg font-bold mb-2">Ongoing support for an extra full cycle, for a total of 2*</h3>
          <p className="italic mb-2">*or up to 4 months if irregular/missing period</p>
          <p className="text-base">
          Consistent, open communication between you and myself is key to building new skills with assurance between calls throughout the program. It also allows for clarity around potentially confusing biomarkers throughout the program. That being said, you are welcome to contact me anytime throughout your enrolment in Flow Freedom, and I will respond as soon as possible. 
          </p>
        </div>
  
        <div>
          <h3 className="text-lg font-bold mb-2">More evidence-based modules & worksheets
          </h3>
          <p className="text-base">
          Everything you are learning comes from years of research and tried and tested methods through FEMM health and the Institute of Integrative Nutrition. I am required to keep my knowledge current to practice successfully and credibly in these fields.          </p>
        </div>
  
        <div>
          <h3 className="text-lg font-bold mb-2">An extra chart follow up, for a total of 2</h3>
          <p className="text-base">
          To ensure you have gained a further level of understanding, this edition includes 2, concise, 15min follow up calls to go over 2 full cycle charts via app/paper charting, answer any questions you may have, help explain what may be going on for you hormonally during your cycle, and ensure you’re confident with your biomarker observations, including cervical fluid and LH testing. 
          </p>
        </div>
  
        <div>
          <h3 className="text-lg font-bold mb-2">Lifestyle recommendations</h3>
          <p className="text-base">
          As required, I will offer you lifestyle modifications - inclusive of nutrition upgrades, stress management practices, sleep hygiene techniques, schedule overhauls, and the like - to help support your health and cycle goals.
          </p>
        </div>
      </div>
    </div>
  ),
  href: '#',
  imageSrc: 'https://tailwindui.com/plus/img/ecommerce-images/product-page-03-product-04.jpg',
  imageAlt: 'Back angled view with bag open and handles to the side.',
},
  {
  id: 3,
  name: <div>Flow Freedom Advanced: <span className="text-sea-pink-700">Pregnancy Prevention & Planning</span></div>,
  href: '#',
  subtext: "*most popular",
  description: (
    <div className="font-roboto">      
           <button
        onClick={onClick}
        type="button"
        className="mb-8 flex w-full items-center justify-center rounded-md border border-transparent bg-sea-pink-700 px-8 py-3 text-base font-medium text-white hover:bg-sea-pink-800 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
      >
        Book free consultation
      </button>
      
      <h2 className="font-roboto text-xl font-bold mb-8">
      WITHIN THIS PROGRAM, YOU WILL RECEIVE EVERYTHING IN FLOW FREEDOM UPLEVELED PLUS       </h2>
      
      <div className="space-y-8">
        <div>
          <h3 className="text-lg font-bold mb-2">An extra live coaching session, for a total of 3</h3>
          <p className="text-base">
          Connect with Eden virtually for a total of 3, powerful, 90min education sessions throughout the program. During these potent sessions, you will be guided through various concepts. From the most basic anatomy refresher, to the intricacies of an optimal cycle, and evidence-based, fertile window exploration to help you prevent or plan for pregnancy. All while exploring what’s happening in YOUR cycle. These are private sessions, allowing you the space and safety to ask any questions, solidify knowledge at your own pace, gain inspiration, share your experiences and successes, and work through activities to ensure you’re feeling confident.           </p>
        </div>
  
        <div>
          <h3 className="text-lg font-bold mb-2">Ongoing support for an extra full cycle, for a total of 3*</h3>
          <p className="italic mb-2">*or up to 6 months if irregular/missing period</p>
          <p className="text-base">
          Consistent, open communication between you and myself is key to building new skills with assurance between calls throughout the program. It also allows for the fertility awareness method to safely act as a form of contraception or pregnancy planning tool even while you’re learning the concepts. That being said, you are welcome to contact me anytime throughout your enrolment in Flow Freedom, and I will respond as soon as possible.           </p>
        </div>
  
        <div>
          <h3 className="text-lg font-bold mb-2">Even more evidence-based modules & worksheets</h3>
          <p className="text-base">
          Everything you are learning comes from years of research and tried and tested methods through FEMM health and the Institute of Integrative Nutrition. I am required to keep my knowledge current to practice successfully and credibly in these fields. 
          </p>
        </div>
  
        <div>
          <h3 className="text-lg font-bold mb-2">An extra chart follow up, for a total of 3</h3>
          <p className="text-base">
          To ensure you have gained a further level of understanding, this edition includes a total of 3, concise, 15min follow up calls to go over 3 full cycle charts via app/paper charting, answer any questions you may have, help explain what may be going on for you hormonally during your cycle, ensure you’re confident with your biomarker observations (cervical fluid and LH testing) and family planning instructions for pregnancy prevention or planning.
          </p>
        </div>
  
        <div>
          <h3 className="text-lg font-bold mb-2">Lifestyle recommendations</h3>
          <p className="text-base">
          As required, I will offer you lifestyle modifications - inclusive of nutrition upgrades, stress management practices, sleep hygiene techniques, schedule overhauls, and the like - to help support your health and cycle goals.           </p>
        </div>
      </div>
    </div>
  ),
  imageSrc: 'https://tailwindui.com/plus/img/ecommerce-images/product-page-03-product-04.jpg',
  imageAlt: 'Back angled view with bag open and handles to the side.',
},
  {
  id: 4,
  name: <div>Flow Freedom Teen: <span className="text-sea-pink-700">Sex Ed. Intensive*</span></div>,
  href: '#',
  subtext: "*a parent/guardian is also required to attend all sessions",
  description: (
    <div className="font-roboto">
      
      <p className="font-roboto italic mb-4">*a parent/guardian is also required to attend all sessions</p>
      
      <button
        onClick={onClick}
        type="button"
        className="mb-8 flex w-full items-center justify-center rounded-md border border-transparent bg-sea-pink-700 px-8 py-3 text-base font-medium text-white hover:bg-sea-pink-800 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
      >
        Book free consultation
      </button>
      
      <h2 className="font-roboto text-xl font-bold mb-8">WITHIN THIS PROGRAM, YOU WILL RECEIVE —</h2>
      
      <div className="space-y-8">
        <div>
          <h3 className="text-lg font-bold mb-2">6 live coaching sessions</h3>
          <p className="text-base">
          Connect with Eden virtually for 6, powerful, 45min education sessions throughout the program. During this session, you will be guided through various concepts. From the most basic anatomy refresher, to the intricacies of an optimal cycle, and how your overall health is impacted by your hormones. All while learning how to observe your cervical fluid biomarkers, and what it all means. This session is done alongside a parent or guardian, so they can learn alongside you, supporting your journey through adolescence and beyond. You will have the space and safety to ask any questions, solidify knowledge, and gain a basic understanding of your cycle and overall health moving forward.           </p>
        </div>
  
        <div>
          <h3 className="text-lg font-bold mb-2">Ongoing support</h3>
          <p className="text-base">
          Consistent, open communication between you, your parent/guardian, and myself is key to building new skills with assurance between calls throughout the program. That being said, you are welcome to contact me anytime throughout your enrolment in Flow Freedom, and I will respond as soon as possible.          </p>
        </div>
  
        <div>
          <h3 className="text-lg font-bold mb-2">Evidence-based modules & worksheets</h3>
          <p className="text-base">
          Everything you are learning comes from years of research and tried and tested methods through FEMM health and the Institute of Integrative Nutrition. I am required to keep my knowledge current to practice successfully and credibly in these fields.           </p>
        </div>
  
        <div>
          <h3 className="text-lg font-bold mb-2">Chart follow ups</h3>
          <p className="text-base">
          To ensure you and your parent/guardian have gained a basic level of understanding, this edition includes 1, concise, 30min follow up call to go over 1 full cycle chart via app/paper charting, answer any questions you may have, and help explain what may be going on for you hormonally during your cycle. If you don’t yet have a menstrual cycle, a practice chart can be used to assess your knowledge.          </p>
        </div>
  
        <div>
          <h3 className="text-lg font-bold mb-2">Lifestyle recommendations</h3>
          <p className="text-base">
          As required, I will offer you lifestyle modifications - inclusive of nutrition upgrades, stress management practices, sleep hygiene techniques, schedule overhauls, and the like - to help support your health and cycle goals.
          </p>
        </div>
      </div>
  
      <div className="mt-12 font-bold text-lg">
        <p>Are you a school or after school group wanting to host a Flow Freedom Teen Program in your area?  <Link className="underline" to="/contact">Click here</Link></p>
      </div>
    </div>
  ),
  imageSrc: 'https://tailwindui.com/plus/img/ecommerce-images/product-page-03-product-04.jpg',
  imageAlt: 'Back angled view with bag open and handles to the side.',
},
  {
  id: 5,
  name: 'Flow Freedom Follow Up*',
  subtext: "*prerequisite: Flow Freedom Advanced",
  href: '#',
  description: (
    <div className="font-roboto">
      
      <p className="font-roboto italic mb-8">*pre-requisite: Flow Freedom Advanced</p>
      
      <button
        onClick={onClick}
        type="button"
        className="mb-8 flex w-full items-center justify-center rounded-md border border-transparent bg-sea-pink-700 px-8 py-3 text-base font-medium text-white hover:bg-sea-pink-800 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
      >
        Book free consultation
      </button>
      
      <h2 className="font-roboto text-xl font-bold mb-8">WHAT YOU WILL RECEIVE —</h2>
      
      <div className="space-y-8">
        <p className="text-base">
        A 30min follow up session, inclusive of additional chart reviews, charting assistance, and/or lifestyle recommendations to help support any new imbalances (i.e. irregular ovulatory patterns, coming off hormonal contraception, postpartum charting, peri-menopause, etc.). This is offered to those who have already completed all education modules in Flow Freedom Advanced, and are seeking additional support.        </p>
      </div>
    </div>
  ),
  imageSrc: 'https://tailwindui.com/plus/img/ecommerce-images/product-page-03-product-04.jpg',
  imageAlt: 'Back angled view with bag open and handles to the side.',
},
]

function FreeEBookPage({ data }) {
  const image = getImage(data?.beachLook)
  const quoteImg = getImage(data?.quoteImg)
  const programCTA = getImage(data?.programCTA)

  return (
    <Layout>
      <SEO
        keywords={[
          `flow freedom program`,
          `sincerely eden`,
          `one on one couching`,
          `Fix Period struggles`,
          "Fix Mental Health",
          "Fix Fatigue",
          "Fix Perdiod pain"
        ]}
        title="Sincerely Eden | Experience Flow Freedom"
        description="A well supported, evidence-based journey to your most empowered life."
      />
      <div>
        <Hero
          quote=""
          ctaBtnText={"Start now"}
          titleStart={<>Experience <br/></>}
          titleEnd="Flow Freedom"
          subtitle={
            <>
              <div className="tracking-widest font-extralight">
                A well supported, evidence-based journey to your most empowered life.
              </div>
              <br />
              <div className="tracking-widest font-extralight">
              Learn the intricacies of your cycle, and uplevel your health, fertility, and overall wellbeing, naturally.
              </div>
            </>
          }
          image={image}
        />
        <Divider />
        <FeaturesList
          title=""
          subtitle={
            <>
            The <span className="text-sea-pink-700">Flow Freedom Programs</span>{" "} were created to help you 
            </>
          }
          subtitle2=""
          features={[
            {
              description:
                "Get to know your cycle and body on the deepest level"
            },
            {
              description:
                "Create sustainable, daily practices to assess your biomarkers"
            },
            {
              description:
                "Ditch conventional birth control safely and effectively"
            },
            {
              description: "Consciously avoid or plan for pregnancy"
            },
            {
              description: "Shed negative beliefs and thought patterns around your cycle and body"
            },
            {
              description: "Get to the root causes of your cycle-related symptoms, and"
            },
            {
               description: <b className="text-2xl">Ultimately stand in your power as a woman.</b>
            }
          ]}
        />
        <Divider />
        <SingleQuote
          img={quoteImg}
          quote="I created various options to suit the health and cycle needs and desires of girls & women across the board. Body literacy should be accessible to all women from a young age. Knowledge is the ultimate freedom."
        />
        <Divider />
        {/* <FourNoImageSection
          title="Program options & inclusions"
          subtitle="Investing in your health as a woman never felt better."
          description="6 months of ongoing support with Eden Sallenbach"
          features={[
            {
              name: "Consults",
              description:
                "12 virtual 1:1 sessions (consistently every 2 weeks).",
              icon: PhoneIcon
            },
            {
              name: "Resources",
              description:
                "Handouts with personalized assignments, dietary + lifestyle recommendations, recipes, and more.",
              icon: BookOpenIcon
            },
            {
              name: "Customization",
              description:
                "Regular plan modifications to allow for growth, as you and your lifestyle evolve.",
              icon: PencilIcon
            },
            {
              name: "Extras",
              description:
                "Optional pantry overhauls + supermarket tours (for local clients only).",
              icon: RefreshIcon
            }
          ]}
        /> */}
         {/* <Divider /> */}
         <p className="text-base font-semibold text-center text-sea-pink-700 tracking-wide uppercase mt-6">
          PROGRAM OPTIONS
          </p>
          <h3 className="mt-6 text-4xl font-extrabold text-gray-900 text-center">
          Investing in your health as a woman never felt better.
          </h3>
          <ProductList products={products}  />
        <Divider />
        <Testimonials />
        <Divider />
      </div>
      <CTABooking programCTA={programCTA} />
    </Layout>
  )
}

export default FreeEBookPage

export const query = graphql`
  query FFPQuery {
    quoteImg: file(relativePath: { eq: "zoomed-about-me.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 600
          height: 650
          quality: 100
          transformOptions: { cropFocus: NORTHWEST }
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    beachLook: file(relativePath: { eq: "beach.jpeg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 700
          height: 700
          quality: 100
          placeholder: BLURRED
          transformOptions: { cropFocus: NORTH }
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    programCTA: file(relativePath: { eq: "sitting-white.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          height: 300
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

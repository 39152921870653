import React from 'react'
import ProductDialog from './productQuickView'
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {useState} from "react"
export default function ProductList({ products }) {
  const data = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "sitting-2.jpeg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 490
            height: 490
            quality: 100
            placeholder: BLURRED
            transformOptions: { cropFocus: SOUTH }
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      image2: file(relativePath: { eq: "sitting-3.jpeg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 490
            height: 490
            quality: 100
            placeholder: BLURRED
            transformOptions: { cropFocus: SOUTH }
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      image3: file(relativePath: { eq: "sitting-1.jpeg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 490
            height: 490
            quality: 100
            placeholder: BLURRED
            transformOptions: { cropFocus: SOUTH }
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      image4: file(relativePath: { eq: "hat-white-wall-standing.jpeg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 490
            height: 490
            quality: 100
            placeholder: BLURRED
            transformOptions: { cropFocus: SOUTH }
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      image5: file(relativePath: { eq: "peaking-curtain.jpeg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 490
            height: 490
            quality: 100
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `)

  const sitting1 = getImage(data?.image1)
  const sitting2 = getImage(data?.image2)
  const sitting3 = getImage(data?.image3)
  const four = getImage(data?.image4)
  const five = getImage(data?.image5)

  const images = [sitting1, sitting2, sitting3, four, five]

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        <h2 className="sr-only">Products</h2>
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
          {products.map((product, i) => (
            <ProductCard
              key={product.id}
              product={product}
              image={images[i]}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

const ProductCard = ({ product, image }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <div
        onClick={() => setIsOpen(true)}
        className="relative bg-white p-4 cursor-pointer"
      >
        {/* Image container */}
        <div className="mb-4 overflow-hidden">
          <GatsbyImage
            className="w-full h-full"
            image={image}
            alt={product?.imageAlt ?? ""}
          />
        </div>
        
        {/* Content */}
        <div className="space-y-2">
          <h3 className="text-lg font-medium text-sea-pink-700">
            {product.name}
          </h3>
          {product.subtext && (
            <p className="text-sm text-gray-600">{product.subtext}</p>
          )}
          <div className="pt-2">
            <span className="text-sm text-gray-700 underline">
              View details →
            </span>
          </div>
        </div>

        {/* Simple border overlay on hover */}
        <div className="absolute inset-0 border-2 border-transparent hover:border-sea-pink-200 transition-colors" />
      </div>

      <ProductDialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        image={
          <GatsbyImage
            className="w-full rounded-lg object-cover"
            image={image}
            alt={product?.imageAlt ?? ""}
          />
        }
        name={product.name}
        description={product.description}
      />
    </>
  )
}
import React from "react"

import Contact from "../components/contact"
import Layout from "../components/layout"
import SEO from "../components/seo"

function ContactPage() {
  return (
    <Layout>
      <SEO
        keywords={[
          `contact`,
          `sincerely eden`,
        ]}
        title="Sincerely Eden | Let&apos;s Chat!"
        description="Unsure what Flow Freedom option is right for you, or want to know more? I&apos;d love to hear from you! Just fill out the form below, and I&apos;ll reply as soon as I can."
      />
      <Contact />
    </Layout>
  )
}

export default ContactPage

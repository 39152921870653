import { Link } from 'gatsby';
import React from 'react';

const testimonials = [
  // {
  //   quote: "I recently worked with Eden in regards to learning about how my cycle and hormones can be used as a form of contraception (and for others, conception). I’ve become increasingly aware over the last several months about how hormones affect our bodies in more ways than I could have imagined. When the opportunity arose to work with Eden, I very hopefully applied, and was chosen to work together via Zoom as I am in Canada and she is in Australia. Prior to our first meeting, I was given a very detailed summary of what to expect during our first session, as well as very detailed resources to print off, and charts to familiarize myself with. Everything was explained in great detail on our zoom session. Eden was incredibly professional. Despite knowing her in childhood, she remained friendly but focused on the task at hand, which I was impressed with. What some may consider awkward issues and terms were discussed, and there was never any judgement from Eden. I continue to chart and graph my cycle as I’m excited to implement my knowledge into my future. I will continue to learn about this topic, as I believe it to be very important. I love the app (Femm) that Eden recommended and will continue to use this daily. Thank you so much, Eden, for your patience and dedication to working with me. It was an amazing experience that I feel every woman can benefit from.",
  //   author: "LG",
  //   location: "Canada",
  //   program: "Flow Freedom Basics"
  // },
  // {
  //   quote: "Working with Eden the past several months has been such a wonderful experience! Not only is she incredibly knowledgeable but you can tell that she is genuinely invested and passionate in helping and empowering women in their bodies...",
  //   author: "RB",
  //   location: "Canada",
  //   program: "Flow Freedom Advanced"
  // },
  // {
  //   quote: "When I first started working with Eden I thought I had a pretty good handle on my cycle and what was happening internally. As we progressed throughout this process I was blown away by the depth of charting with FEMM...",
  //   author: "KB",
  //   location: "Canada",
  //   program: "Flow Freedom Advanced"
  // },
  {
    quote: <div>&quot;After engaging in a workshop that Eden hosted, I came away with great knowledge and more understanding of my body. When the opportunity came to work with Eden to continue this learning and better understand my cycle and body, I jumped at the chance...&quot;</div>,
    author: "JM",
    location: "Australia",
    program: "Flow Freedom Advanced"
  },
  {
    quote: <div>&quot;Eden has such a magical aura of softness and femininity that instantly puts you at ease. Thank you for holding such a sacred space.&quot;</div>,
    author: "JM",
    location: "Australia",
  },
  {
    quote: <div>&quot;I never realized how in tune I actually am with my body. Eden helped remind me of this, and I am so grateful she did.&quot;</div>,
    author: "SR",
    location: "Canada",
  },
  {
    quote: <div>&quot;Instead of calling it &apos;shark week&apos; I now call it &apos;honour my body&apos; week and put a lot less pressure on myself than in the past. Eden taught me how to change the language and embrace my womanhood and it&apos;s liberating.&quot;</div>,
    author: "CL",
    location: "Canada",
  },
  {
    quote: <div>&quot;Body awareness is everything. I never realized how much alcohol affected me and my hormones until Eden helped me tune in.&quot;</div>,
    author: "AJ",
    location: "Canada",
  },
  {
    quote: <div>&quot;For anyone looking to make healthy changes, improve their hormonal health and find balance, Eden is your girl. There&apos;s so much information out there and it was so helpful to have someone so knowledgeable to discuss my goals.&quot;</div>,
    author: "DO",
    location: "Canada",
  }
];

const TestimonialCard = ({ quote, author, location, program }) => {
  

  return (
    <div className="flex h-full flex-col rounded-lg bg-white p-6 shadow-md">
      <blockquote className="flex-1">
        <p className="text-gray-600 italic">{quote}</p>
      </blockquote>
      
      <footer className="mt-4">
        <p className="text-pink-600 font-semibold">
          {author}, {location} 
          {program && <span className="text-red-600 block">{program}</span>}
        </p>
      </footer>
    </div>
  );
};

const TestimonialsGrid = () => {
  return (
    <div className="py-12 px-4 sm:px-6 lg:px-8">
      <div className="mx-auto max-w-7xl">
        <h2 className="text-center text-3xl font-bold text-gray-900 mb-12">
         What Women Are Saying
        </h2>
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
          {testimonials.map((testimonial, index) => (
            <TestimonialCard
              key={index}
              quote={testimonial.quote}
              author={testimonial.author}
              location={testimonial.location}
              program={testimonial.program}
            />
          ))}
        </div>
      </div>
      <div  className="flex items-center justify-center mt-16">
      <Link
        to="/testimonials"
        type="submit"
        className="flex items-center justify-center py-3 px-5 border border-transparent text-base font-medium rounded-md text-white bg-porsche-700 hover:bg-porsche-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-porsche-500"
      >
        See all testimonials
      </Link>
      </div>
    </div>
  );
};

export default TestimonialsGrid;
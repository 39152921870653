/* eslint-disable */

import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

export default function Example({ images }) {
  const captions = [
    "Girl Talk: I have been OFF hormonal birth control for 11.5 years, copper IUD-less for 5.5 years, and ON @naturalcycles for 2.5 years, and it just feels right",
    "Back when I was pregnant, wearing the most elegant set by @juemwoman and sleeping comfortable with my bump thx to @butterflymaternity",
    "In a funk? Wash your hair, hang with friends, do some yoga, snuggle puppies. Thx for listening to my Ted Talk",
    "Why we're deciding to spend $2k on a Doula versus a SNOO",
    "Flowers, fruit, frothy waves, and feminine vibes: 4 F-words that make me fine, fabulous, and free inside and out",
    "simple pleasures as of late:slow mornings",
    "What better way to start another week than with a (decaf) oat milk flat white and an easy, breezy, eco-friendly & sustainable underwear set?",
    "My word for 2022 is L I G H T",
  ];


  return (
    <ul
      role="list"
      className="grid grid-cols-2 sm:grid-cols-3 gap-4 gap-y-8 sm:gap-6 lg:grid-cols-4 lg:gap-8"
    >
      {images.map((file, i) => {
        return (
          <li key={i} className="relative">
            <a
              href="https://www.instagram.com/sincerelyeden/"
              target="__blank"
              rel="noreferrer"
              className="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden"
            >
              <div>
                {file && (
                  <GatsbyImage
                    className="object-cover pointer-events-none group-hover:opacity-75 absolute"
                    image={file}
                    alt={file?.alt ?? ""}
                  />
                )}
              </div>
            </a>
            <p className="mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none">
              {captions[i]}
            </p>
          </li>
        )
      })}
    </ul>
  )
}

import React from "react"
import { getImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import Hero from "../components/hero"
import Layout from "../components/layout"
import SEO from "../components/seo"
import useMailchimp from "../hooks/useMailchimp"

function ProgramsPage({ data }) {
  const image = getImage(data?.prgramsHeroImg)
  const { add, isLoading, isSuccess } = useMailchimp()

  return (
    <Layout>
      <SEO
        keywords={[
          `free flow challenge`,
          `sincerely eden`,
          `challenge`,
          `program`
        ]}
        title="Sincerely Eden | TABOO no more: FREEBIE!"
        description="Get a glimpse into the Flow Freedom Programs with this FREE ebook sent straight to your inbox."
      />
      <Hero
        cta={true}
        ctaBtnText={"I want it!"}
        quote=""
        titleStart={<>TABOO no more: </>}
        titleEnd={<>FREEBIE!</>}
        subtitle="Get a glimpse into the Flow Freedom Programs with this FREE ebook sent straight to your inbox."
        image={image}
        onSubmit={inputText => add(inputText, ["10DayChallenge"])}
        isLoading={isLoading}
        isSuccess={isSuccess}
      />
    </Layout>
  )
}

export default ProgramsPage

export const query = graphql`
  query ProgramHeroImageQuery {
    prgramsHeroImg: file(relativePath: { eq: "taboo-no-more.jpeg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 600
          height: 950
          quality: 100
          transformOptions: { cropFocus: SOUTHEAST }
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`
